<template>
    <div class="main-page">
      <section class="page-section">
        <div
          class="grid justify-content-center p-2 lg:p-0"
          style="min-width: 80%"
        >
          <div
            class="col-12 mt-5"
            style="
              border-radius: 56px;
              padding: 0.3rem;
              background: linear-gradient(
                rgb(158, 14, 122) 10%,
                rgba(173, 39, 39, 0) 30%
              );
            "
          >
            <div
              class="flex justify-content-center h-full w-full m-0 py-4 px-4"
              style="
                border-radius: 53px;
                background: linear-gradient(
                  180deg,
                  var(--surface-50) 38.9%,
                  var(--surface-0)
                );
              "
            >
              <div class="grid flex-column align-items-center">
                <div class="col-12 flex align-items-center py-5 border-300">
                  <router-link to="/">
                    <div class="grid">
                      <div class="col-12 ml-4">
                        <span class="text-gray-600">{{
                          $t("your_application_has_been_closed")
                        }}</span>
                      </div>
                    </div>
                  </router-link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  </template>
  <script>
  export default {
    name: "closedApplicationSvgPage",
    components: {},
  };
  </script>
  